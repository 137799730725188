export default [
  {
    key: "name",
    label: "Name",
    visible: true,
  },
  {
    key: "type_ncr",
    label: "Type NCR",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "module",
    label: "module",
    visible: true,
    class: "text-center",
  },
  {
    key: "created_by",
    label: "requested by",
    visible: true,
  },
  {
    key: "payment",
    label: "payment",
    visible: true,
    class: "text-right",
  },
  {
    key: "expenses",
    label: "expenses",
    visible: true,
    class: "text-right",
  },
  {
    key: "balance",
    label: "balance",
    visible: true,
    class: "text-right",
  },
];
