Chart.defaults.RoundedDoughnut = Chart.helpers.clone(Chart.defaults.doughnut);
Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
  draw: function (ease) {
    const ctx = this.chart.ctx;
    const easingDecimal = ease || 1;
    const arcs = this.getMeta().data;

    const redSegmentIndex = 0; // Índice del segmento rojo
    const roundedArc = arcs[redSegmentIndex];

    Chart.helpers.each(arcs, function (arc, i) {
      if (arc.hidden) {
        // Omitir el dibujo si el arco está oculto
        return;
      }
      arc.transition(easingDecimal).draw();

      const vm = arc._view;
      const radius = (vm.outerRadius + vm.innerRadius) / 2;
      const thickness = (vm.outerRadius - vm.innerRadius) / 2;
      const startAngle = Math.PI - vm.startAngle - Math.PI / 2;
      const angle = Math.PI - vm.endAngle - Math.PI / 2;

      ctx.save();
      ctx.translate(vm.x, vm.y);
      if (arc !== roundedArc && vm.circumference > 0) {
        // Aplicar bordes redondeados a todos los segmentos excepto el rojo cuando el valor es mayor a cero
        ctx.fillStyle = vm.backgroundColor;
        ctx.beginPath();
        ctx.arc(
          radius * Math.sin(startAngle),
          radius * Math.cos(startAngle),
          thickness,
          0,
          2 * Math.PI
        );
        ctx.arc(
          radius * Math.sin(angle),
          radius * Math.cos(angle),
          thickness,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fill();
      }

      ctx.restore();
    });
  },
});
Chart.plugins.register({
  afterDraw: function (chart) {
    if (chart.config.options.plugins.centerText) {
      const ctx = chart.chart.ctx;
      const centerText = chart.config.options.plugins.centerText;
      const fontSize = 20;
      const lineHeight = 30;

      // Obtener el área de dibujo del gráfico (excluyendo los márgenes internos)
      const chartArea = chart.chartArea;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY =
        (chartArea.top + chartArea.bottom) / 2 -
        (lineHeight * (centerText.lines.length - 1)) / 2;

      ctx.save();
      ctx.font = `bold ${fontSize}px Arial`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#ADADAD";

      centerText.lines.forEach((line, index) => {
        ctx.fillText(line, centerX, centerY + lineHeight * index);
      });

      ctx.restore();
    }
  },
});
