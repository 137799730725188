<template>
  <div>
    <div class="d-flex justify-content-end mb-1 px-1" style="gap: 15px">
      <div class="item-action">
        <b-form-select
          v-model="paymentMonth"
          :options="optionMonths"
          @input="reloadInformation"
        />
      </div>
      <div class="item-action">
        <b-form-select v-model="paymentYear" :options="optionYears" />
      </div>
      <div class="item-action">
        <b-form-select
          v-model="clientOrLeadSelected"
          :options="statusOptionClientOrLead"
        />
      </div>
      <div class="item-action">
        <b-form-select
          :disabled="clientOrLeadSelected !== 2"
          v-model="clientTypeSelected"
          :options="statusOptionClientType"
        />
      </div>
      <div class="item-action">
        <b-button variant="primary" @click="updateAllCard">
          <feather-icon icon="RefreshCcwIcon" /> Refresh
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="12" md="12" lg="3">
        <cards
          :year="paymentYear"
          :month="paymentMonth"
          :typeClient="clientTypeSelected"
          :clientOrLead="clientOrLeadSelected"
          ref="cardNcrWpUp"
        />
      </b-col>
      <b-col cols="12" md="12" lg="9" class="row justify-content-center">
        <div class="col-12 col-md-12 col-lg-6">
          <bar-chart
            :date="dateData"
            :typeClient="clientTypeSelected"
            :year="paymentYear"
            :clientOrLead="clientOrLeadSelected"
            ref="barChartPayments"
          />
        </div>
        <div class="col-12 col-md-12 col-lg-6">
          <line-chart
            :date="dateData"
            :year="paymentYear"
            :typeClient="clientTypeSelected"
            :clientOrLead="clientOrLeadSelected"
            ref="lineChartStatus"
          />
        </div>
        <div class="col-12 col-md-12 col-lg-12 justify-content-center">
          <card-report
            ref="cardReport"
            :year="paymentYear"
            :month="paymentMonth"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cards from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ncr/Cards.vue";
import BarChart from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ncr/BarChart.vue";
import LineChart from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ncr/LineChart.vue";
import CardReport from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ncr/CardReport.vue";
import moment from "moment";

export default {
  components: {
    Cards,
    BarChart,
    LineChart,
    CardReport,
  },
  data() {
    return {
      clientTypeSelected: 0,
      clientOrLeadSelected: 0,
      paymentMonth: moment().format("MM") * 1,
      paymentYear: moment().format("YYYY"),
      dateData: moment().format("YYYY-MM-01"),
      optionMonths: moment
        .months()
        .map((month, index) => ({ text: month, value: index + 1 })),
      statusOptionClientType: [
        { text: "All", value: 0 },
        { text: "Regular", value: 1 },
        { text: "Digital", value: 2 },
      ],
      statusOptionClientOrLead: [
        { text: "All", value: 0 },
        { text: "Client", value: 2 },
        { text: "Lead", value: 1 },
      ],
    };
  },
  computed: {
    optionYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = 2023; year <= currentYear; year++) {
        years.push({ text: year, value: year });
      }
      return years;
    },
  },
  methods: {
    reloadInformation() {
      const month = moment(this.paymentMonth, "MM").format("MM");
      this.dateData = `${this.paymentYear}-${month}-01`;
    },
    handleCheckboxChangeDash() {
      this.$router.push({ name: "analyst-dashboard" });
    },
    async updateAllCard() {
      try {
        this.addPreloader();
        await Promise.all([
          this.$refs["barChartPayments"].getChartNcrPayment(),
          this.$refs["lineChartStatus"].getChartNcrAuto(),
          this.$refs["cardNcrWpUp"].getCardsData(),
          this.$refs["cardReport"].getReportBalanceBar(),
          this.$refs["cardReport"].getReportBalanceDoughnut(),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.custom-checkbox-dash >>> .custom-control-label {
  padding-left: 0px !important;
}
.item-action {
  flex: 0 0 10%;
}
</style>
