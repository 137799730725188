<template>
  <b-overlay :show="isLoading" rounded="sm" :variant="skin">
    <b-card
      :style="{ borderRadius: '15px' }"
      :class="skin === 'dark' ? 'bg-dark' : ''"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h3
          :class="skin === 'dark' ? 'text-w' : 'text-secondary'"
          style="font-weight: bold"
          class="mb-0"
        >
          NCR STATUS FOR THE MONTH
        </h3>
        <b-form-checkbox
          class="custom-checkbox-chart-dash"
          :class="[
            skin === 'dark' ? 'text-w' : 'text-secondary',
            checked
              ? 'custom-checkbox-chart-dash-dark'
              : 'custom-checkbox-chart-dash-light',
          ]"
          switch
          size="lg"
          v-model="checked"
          @change="handleCheckboxChange"
        >
          <b-badge
            :class="[
              checked ? 'custom-badge-ckecked' : 'custom-badge-not-ckecked',
            ]"
          >
            {{ checked ? "MANUAL" : "AUTOMATIC" }}
          </b-badge>
        </b-form-checkbox>
      </div>

      <e-charts ref="line" auto-resize :options="option" theme="theme-color" />
    </b-card>
  </b-overlay>
</template>

<script>
import ECharts from "vue-echarts"
import "echarts/lib/component/tooltip"
import "echarts/lib/component/legend"
import "echarts/lib/chart/line"
import "echarts/lib/chart/bar"
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index"

export default {
  components: {
    ECharts,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
    date: { type: String, default: null },
    typeClient: Number,
    clientOrLead: Number,
  },
  data() {
    return {
      checked: false,
      option: {
        grid: {
          width: "98%",
          left: "15px",
          right: "15px",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        pointStyle: "line",
        legend: {
          bottom: 0,
          type: "plain",
          icon: "circle",
        },
        xAxis: {
          boundaryGap: true,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          splitLine: { show: true },
          stepSize: 50,
          height: 500,
        },
        series: [
          {
            name: "Pending",
            type: "line",
            color: "#34EAFD",
            data: [],
            smooth: true,
            icon: "circle",
          },
          {
            name: "Failed",
            type: "line",
            color: "#173fbc",
            data: [],
            smooth: true,
          },
          {
            name: "Completed",
            type: "line",
            color: "#4caf50",
            data: [],
            smooth: true,
          },
          {
            name: "Annulled",
            type: "line",
            color: "#FD3817",
            data: [],
            smooth: true,
          },
          {
            name: "Failed attempt",
            type: "line",
            color: "#7D3C98",
            data: [],
            smooth: true,
          },
        ],
      },
      isLoading: false,
    }
  },
  methods: {
    async getChartNcrAuto() {
      try {
        this.isLoading = true
        const { days } = await SpecialistsDashboardService.getChartNcrAuto({
          date: this.date,
          type: this.checked ? 2 : 1,
          client_type_id: this.typeClient,
          client_or_lead: this.clientOrLead,
        })
        this.option.xAxis.data = days.map((day) => day.day)
        this.option.series[0].data = days.map((day) => day.pending)
        this.option.series[1].data = days.map((day) => day.failed)
        this.option.series[2].data = days.map((day) => day.completed)
        this.option.series[3].data = days.map((day) => day.annulled)
        this.option.series[4].data = days.map((day) => day.failed_attempt)
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleCheckboxChange() {
      this.getChartNcrAuto()
    },
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"]
    },
  },
  watch: {
    date() {
      this.getChartNcrAuto()
    },
    typeClient() {
      this.getChartNcrAuto()
    },
    clientOrLead() {
      this.getChartNcrAuto()
    },
  },
  async created() {
    await this.getChartNcrAuto()
  },
}
</script>

<style scoped>
.echarts {
  width: 100% !important;
}
.bg-dark {
  background: #17171a !important;
}
.custom-checkbox-chart-dash >>> .custom-control-label {
  padding-left: 0px !important;
}
.text-w {
  color: #fff !important;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:not(:checked)
  ~ .custom-control-label:before {
  background-color: #0090e7 !important;
}
.custom-checkbox-chart-dash >>> label {
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin: 0 !important;
  padding: 5px;
}
.custom-checkbox-chart-dash-dark
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #dc3545 !important;
  border: 1px solid #ffffff;
}
.custom-checkbox-chart-dash-light
  >>> .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #dc3545 !important;
}
.custom-badge-ckecked {
  background: #dc3545;
  color: #fff;
}
.custom-badge-not-ckecked {
  background: #0090e7 !important;
  color: #fff;
}
</style>
