<template>
  <b-card>
    <b-overlay :show="isLoading" rounded="sm" :variant="skin">
      <div class="row justify-content-between">
        <div class="col-10 d-flex col-md-3">
          <h3 :class="skin == 'dark' ? 'text-w' : 'text-secondary'">
            REPORT BALANCE
          </h3>
          <feather-icon
            class="ml-1 text-warning"
            icon="HelpCircleIcon"
            size="25"
            v-b-tooltip.hover="'Client and Lead Report'"
          />
        </div>
        <div class="col-2 col-md-1 text-right">
          <feather-icon
            class="cursor-pointer"
            icon="ListIcon"
            size="25"
            @click="showModal"
            v-b-tooltip.hover="'See more details'"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-6 d-flex row justify-content-center">
          <div class="col-12"><canvas ref="chartReport"></canvas></div>
          <div
            class="col-10 row align-items-center mt-3 justify-content-center"
          >
            <ul class="col-4 p-0 m-0">
              <b-media tag="li">
                <template #aside>
                  <b-img
                    blank
                    blank-color="#0090e7"
                    width="15"
                    alt="placeholder"
                    rounded
                  ></b-img>
                </template>
                <h5 class="mt-0">Payment</h5>
              </b-media>
            </ul>
            <div
              class="col-5 d-flex p-0 justify-content-end align-items-center"
            >
              <h5
                class="mr-1"
                :class="skin == 'dark' ? 'text-w' : 'text-secondary'"
              >
                $ {{ totalPayment }}
              </h5>

              <feather-icon class="text-success" icon="PlusIcon" size="25" />
            </div>
          </div>
          <hr
            style="border: 1px solid rgb(70 68 68); width: 310px"
            class="mt-0"
          />
          <div class="col-10 row align-items-center justify-content-center">
            <ul class="col-4 p-0 m-0">
              <b-media tag="li">
                <template #aside>
                  <b-img
                    blank
                    blank-color="#dc3545"
                    width="15"
                    alt="placeholder"
                    rounded
                  ></b-img>
                </template>
                <h5 class="mt-0">Expenses</h5>
              </b-media>
            </ul>
            <div
              class="col-5 d-flex p-0 justify-content-end align-items-center"
            >
              <h5
                class="mr-1"
                :class="skin == 'dark' ? 'text-w' : 'text-secondary'"
              >
                $ {{ totalExpenses }}
              </h5>

              <feather-icon class="text-danger" icon="MinusIcon" size="25" />
            </div>
          </div>
          <hr
            style="border: 1px solid rgb(70 68 68); width: 310px"
            class="mt-0"
          />
          <div class="col-10 row justify-content-center align-items-center">
            <ul class="col-4 p-0 m-0">
              <b-media tag="li">
                <template #aside>
                  <b-img
                    blank
                    blank-color="#00d25b"
                    width="15"
                    alt="placeholder"
                    rounded
                  ></b-img>
                </template>
                <h5 class="mt-0">Balance</h5>
              </b-media>
            </ul>
            <div
              class="col-5 d-flex p-0 justify-content-end align-items-center"
            >
              <h5
                class="mr-1"
                :class="skin == 'dark' ? 'text-w' : 'text-secondary'"
              >
                $ {{ totalBalance }}
              </h5>

              <feather-icon
                :class="totalBalance > 0 ? 'text-success' : 'text-danger'"
                :icon="totalBalance > 0 ? 'TrendingUpIcon' : 'TrendingDownIcon'"
                size="25"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <vue-apex-charts
            type="bar"
            width="100%"
            :options="chartOptions"
            :series="series"
            height="380"
          />
        </div>
      </div>
    </b-overlay>
    <report-modal v-if="stateModal" @close="hideModal" />
  </b-card>
</template>

<script>
import Chart from "chart.js";
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import VueApexCharts from "vue-apexcharts";
import ReportModal from "@/views/specialists/views/dashboard_v2/components/modal/ReportModal.vue";
import plugin from "@/views/specialists/sub-modules/analyst-department/views/dashboard/utils/doughnutEmptyState.js";
export default {
  components: {
    VueApexCharts,
    ReportModal,
  },
  props: {
    year: {
      type: [Number, String],
      required: false,
    },
    month: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      chartSeriesReport: [4, 6],
      max: 2000,
      isLoading: false,
      stateModal: false,
      doughnutData: [],
      chartInstanceReportBalance: null,
      dataReportBalance: {
        labels: ["100 Pending payment", "200 Complete payment"],
        datasets: [
          {
            data: this.chartSeriesReport,
            backgroundColor: [],
            borderWidth: 0,
          },
        ],
      },

      series: [
        {
          name: "Payment",
          data: [44, 55, 41, 64, 22, 43, 21],
        },
        {
          name: "Expenses",
          data: [53, 32, 33, 52, 13, 44, 32],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: [],
            fontWeight: 600,
          },
          formatter: function (val, opt) {
            return "$ " + val;
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          theme: "dark",
          followCursor: true,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#82868b",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#82868b",
            },
          },
        },
        crosshairs: {
          show: false,
          width: 1,
        },
        colors: [],

        fill: {
          opacity: 1,
          type: "solid",
        },
        legend: {
          labels: {
            colors: ["#82868b"],
          },
          fontWeight: 400,
        },
      },
    };
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
    totalExpenses() {
      return this.calculateTotal(this.doughnutData, "expenses");
    },
    totalPayment() {
      return this.calculateTotal(this.doughnutData, "payment");
    },
    totalBalance() {
      return this.calculateTotal(this.doughnutData, "balance");
    },
  },
  methods: {
    renderChartReport() {
      if (this.chartInstance != null) {
        this.destroyChart();
      }
      const chartReport = this.$refs.chartReport;
      if (!chartReport) return;
      this.chartInstance = new Chart(chartReport, {
        type: "RoundedDoughnut",
        data: this.dataReportBalance,
        options: {
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
              fontColor: "#928F8B",
              padding: 19,
            },
            align: "center",
            display: false,
          },
          cutoutPercentage: 75,
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          plugins: {
            centerText: {
              lines: ["Balance", `$ ${this.totalBalance}`],
            },
            emptyDoughnut: {
              color: "#6c757d",
              width: 20,
              radiusDecrease: 20,
            },
          },
        },
        plugins: [plugin],
      });
    },
    async getReportBalanceBar() {
      try {
        this.isLoading = true;
        const params = {
          year: this.year,
          month: this.month,
          type_ncr: 1,
        };
        const data = await SpecialistsDashboardService.getReportBalanceBar(
          params
        );

        this.chartOptions = {
          xaxis: {
            categories: data.map((item) => {
              return item.name;
            }),
          },
        };

        this.series[0].data = data.map((item) => {
          return item.payment;
        });
        this.series[1].data = data.map((item) => {
          return item.expenses;
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },

    async getReportBalanceDoughnut() {
      if (this.chartInstanceReportBalance != null) {
        this.destroyChart();
      }
      try {
        const params = {
          year: this.year,
          month: this.month,
          type_ncr: 1,
        };
        const data = await SpecialistsDashboardService.getReportBalanceDoughnut(
          params
        );
        this.doughnutData = data;
        this.dataReportBalance.datasets[0].data =
          data[0].balance != null && data[0].balance != 0
            ? [data[0].payment, data[0].expenses]
            : [];
        this.dataReportBalance.labels = [`Payment`, `Expenses`];
        await this.renderChartReport();
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      }
    },

    initializeChartColors() {
      const colors = ["#0090e7", "#dc3545"];

      this.dataReportBalance.datasets[0].backgroundColor = colors;
      this.chartOptions.colors = colors;
      this.chartOptions.chart.toolbar.show = false;
      this.chartOptions.plotOptions.bar.horizontal = true;
      this.chartOptions.plotOptions.bar.dataLabels.position = "top";
      this.chartOptions.dataLabels = {
        enabled: true,
        offsetX: -10,
        style: {
          colors: ["#fff"],
          fontSize: "14px",
          fontWeight: 600,
        },
        formatter: function (val, opt) {
          return "$ " + val;
        },
      };
    },

    showModal() {
      this.stateModal = true;
    },
    hideModal() {
      this.stateModal = false;
    },
    destroyChart() {
      this.chartInstance.destroy();
    },

    calculateTotal(dataArray, key) {
      return dataArray
        .reduce((total, object) => total + Number(object[key]), 0)
        .toFixed(2);
    },
  },

  created() {
    this.initializeChartColors();
    this.getReportBalanceBar();
    this.getReportBalanceDoughnut();
  },
  watch: {
    year() {
      this.getReportBalanceDoughnut();
      this.getReportBalanceBar();
      this.destroyChart();
    },
    month() {
      this.getReportBalanceDoughnut();
      this.getReportBalanceBar();
      this.destroyChart();
    },
    skin(value) {
      this.destroyChart();
      this.renderChartReport();
      this.chartOptions = {
        dataLabels: {
          style: {
            colors: value == "dark" ? ["#fff"] : ["#464647"],
          },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-weight: bold;
  color: #fff;
}
</style>
