<template>
  <b-modal
    v-model="isActive"
    size="xlg"
    title="List Report"
    title-tag="h3"
    hide-footer
    modal-class="modal-primary"
    scrollable
    @hidden="closeModal"
    body-class="p-0"
  >
    <b-tabs
      pills
      lazy
      nav-class="mt-1"
      active-nav-item-class="bg-primary box-shadow-info"
      @input="handleTabChange"
      justified
      card
      fill
    >
      <template v-slot:tabs-end>
        <b-row>
          <b-col sm="12">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><strong class="text-primary"
                    >Modules
                  </strong></b-input-group-text
                >
              </template>
              <b-form-select
                :options="optionsModules"
                v-model="selectedModuleId"
                @input="reloadTable"
              >
                <b-form-select-option :value="null"> All </b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><strong class="text-primary"
                    >Type NCR</strong
                  ></b-input-group-text
                >
              </template>
              <b-form-select
                :options="optionsTypeNcr"
                v-model="selectedTypeNcr"
                @input="reloadTable"
              >
              </b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
      </template>

      <b-tab :title-link-class="[bgTabsNavs, 'sub-tab px-3']" title="Clients">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="reloadTable"
        >
        </b-pagination>
        <b-table
          ref="table-search-report"
          :per-page="perPage"
          :current-page="currentPage"
          :items="myProvider"
          :fields="visibleFields"
          show-empty
          foot-clone
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(name)="data">
            <span class="d-flex align-items-center" style="gap: 5px">
              <template v-if="data.item.status_account != null"> </template>
              <router-link
                v-if="data.item.client_account_id != null"
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${data.item.client_account_id}`,
                  },
                }"
              >
                {{ data.item.name }}
              </router-link>
            </span>
            <status-account :account="data.item" :text="true"></status-account>
            <span class="d-block">
              {{ data.item.account }}
            </span>
          </template>
          <template #cell(module)="data">
            <div class="text-info text-center">
              {{ nameModule(data.item.module) }}
            </div>
          </template>
          <template #cell(created_by)="data">
            <div>
              {{ data.item.created_by }}
              <br />
              {{ data.item.created_at | myGlobalDay }}
            </div>
          </template>
          <template #cell(payment)="data">
            <b-badge variant="light-primary">$ {{ data.item.payment }}</b-badge>
          </template>
          <template #cell(expenses)="data">
            <b-badge variant="light-danger">$ {{ data.item.expenses }}</b-badge>
          </template>
          <template #cell(balance)="data">
            <b-badge
              :variant="
                data.item.payment - data.item.expenses < 0
                  ? 'light-danger'
                  : 'light-success'
              "
              >$
              {{ (data.item.payment - data.item.expenses).toFixed(2) }}</b-badge
            >
          </template>
          <template #cell(type_ncr)="data">
            <b-badge
              variant="light-warning"
              v-if="data.item.type_ncr == 'MANUAL'"
            >
              {{ data.item.type_ncr }}
            </b-badge>
            <b-badge variant="light-info" v-else>
              {{ data.item.type_ncr }}
            </b-badge>
          </template>
          <template v-slot:foot(name)> - </template>
          <template v-slot:foot(module)> - </template>
          <template v-slot:foot(type_ncr)> - </template>
          <template v-slot:foot(created_by)>
            <b-badge variant="info" class="px-1 h1">TOTAL</b-badge>
          </template>
          <template v-slot:foot(payment)>
            <b-badge variant="light-primary" style="font-size: 15px">
              $ {{ items.length != 0 ? items[0].totalPayment : "-" }}
            </b-badge>
          </template>
          <template v-slot:foot(expenses)>
            <b-badge variant="light-danger" style="font-size: 15px">
              $
              {{ items.length != 0 ? items[0].totalExpenses : "-" }}
            </b-badge>
          </template>
          <template v-slot:foot(balance)>
            <b-badge
              style="font-size: 15px"
              :variant="
                items.length != 0
                  ? items[0].totalPayment - items[0].totalExpenses < 0
                    ? 'light-danger'
                    : 'light-success'
                  : ''
              "
            >
              $ {{ totalBalance }}
            </b-badge>
          </template>
        </b-table>
      </b-tab>
      <b-tab :title-link-class="[bgTabsNavs, 'sub-tab px-3']" title="Leads">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="reloadTable"
        >
        </b-pagination>
        <b-table
          ref="table-search-report"
          :per-page="perPage"
          :current-page="currentPage"
          :items="myProvider"
          show-empty
          :fields="visibleFields"
          foot-clone
          hover
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(name)="data">
            <span class="d-flex align-items-center" style="gap: 5px">
              <template v-if="data.item.account != null">
                <status-account
                  :account="data.item"
                  :text="false"
                ></status-account>
              </template>
              <router-link
                v-else
                class="text-warning"
                style="font-weight: bolder"
                target="_blank"
                :to="{
                  name: leadDashboardRouteName,
                  params: {
                    id: `${data.item.lead_id}`,
                  },
                }"
              >
                {{ data.item.name }}
              </router-link>
            </span>
            <span class="d-block" style="margin-left: 18px">
              {{ data.item.client_account }}
            </span>
          </template>
          <template #cell(module)="data">
            <div class="text-info text-center">
              {{ nameModule(data.item.module) }}
            </div>
          </template>
          <template #cell(created_by)="data">
            <div>
              {{ data.item.created_by }}
              <br />
              {{ data.item.created_at | myGlobalDay }}
            </div>
          </template>
          <template #cell(payment)="data">
            <b-badge variant="light-primary">$ {{ data.item.payment }}</b-badge>
          </template>
          <template #cell(expenses)="data">
            <b-badge variant="light-danger">$ {{ data.item.expenses }}</b-badge>
          </template>
          <template #cell(balance)="data">
            <b-badge
              :variant="
                data.item.payment - data.item.expenses < 0
                  ? 'light-danger'
                  : 'light-success'
              "
              >$
              {{ (data.item.payment - data.item.expenses).toFixed(2) }}</b-badge
            >
          </template>
          <template #cell(type_ncr)="data">
            <b-badge
              variant="light-warning"
              v-if="data.item.type_ncr == 'MANUAL'"
            >
              {{ data.item.type_ncr }}
            </b-badge>
            <b-badge variant="light-info" v-else>
              {{ data.item.type_ncr }}
            </b-badge>
          </template>
          <template v-slot:foot(name)> - </template>
          <template v-slot:foot(module)> - </template>
          <template v-slot:foot(type_ncr)> - </template>
          <template v-slot:foot(created_by)>
            <b-badge variant="info" class="px-1 h1">TOTAL</b-badge>
          </template>
          <template v-slot:foot(expenses)>
            <b-badge variant="light-danger" style="font-size: 15px">
              $
              {{ items.length != 0 ? items[0].totalExpenses : "-" }}
            </b-badge>
          </template>
          <template v-slot:foot(payment)>
            <b-badge variant="light-primary" style="font-size: 15px">
              $ {{ items.length != 0 ? items[0].totalPayment : "-" }}
            </b-badge>
          </template>
          <template v-slot:foot(balance)>
            <b-badge
              style="font-size: 15px"
              :variant="
                items.length != 0
                  ? items[0].totalPayment - items[0].totalExpenses < 0
                    ? 'light-danger'
                    : 'light-success'
                  : ''
              "
            >
              $ {{ totalBalance }}
            </b-badge>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import Fields from "../fields/fieldsModalReport";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    StatusAccount,
  },
  data() {
    return {
      isActive: false,
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      fields: Fields,
      tabIndex: 0,
      entityType: null,
      items: [],
      optionsModules: null,
      optionsTypeNcr: [
        { value: null, text: "All" },
        { value: 1, text: "Automatic" },
        { value: 2, text: "Manual" },
      ],
      selectedTypeNcr: null,
      selectedModuleId: null,
      isBusy: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((fields) => fields.visible);
    },
    totalBalance() {
      return this.items.length != 0
        ? (this.items[0].totalPayment - this.items[0].totalExpenses).toFixed(2)
        : "-";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    clientDashboardRouteName() {
      const routers = {
        11: "specialists-client-dashboard",
        25: "sp-digital-dashboard-attendance",
      };
      return routers[this.moduleId] || null;
    },
    leadDashboardRouteName() {
      const routers = {
        11: "specialist-lead-show",
        25: "specialist-dg-lead-show",
        28: "specialist-lead-show",
      };

      return routers[this.moduleId] || null;
    },
  },
  methods: {
    closeModal() {
      this.totalRows = 0;
      this.perPage = 10;
      this.currentPage = 1;
      this.isActive = false;
      this.$emit("close");
    },
    async myProvider(ctx) {
      try {
        this.isBusy = true;
        const params = {
          perPage: ctx.perPage,
          nPage: ctx.currentPage,
          entityType: this.entityType,
          moduleId: this.selectedModuleId,
          typeNcr: this.selectedTypeNcr,
        };
        const data = await SpecialistsDashboardService.getReportModalDash(
          params
        );

        if (data.data.length != 0) {
          this.totalRows = data.total;
        } else {
          this.totalRows = 0;
        }
        this.perPage = data.per_page;
        this.currentPage = data.current_page;
        this.items = data.data;
        return data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    async reloadTable() {
      if (this.$refs["table-search-report"]) {
        this.$refs["table-search-report"].refresh();
      }
    },

    async getModules() {
      try {
        const data = await SpecialistsDashboardService.getModules();
        this.optionsModules = data;
        return data;
      } catch (error) {}
    },

    async handleTabChange(newTabIndex) {
      if (newTabIndex == 0) {
        this.entityType = "client";
      } else {
        this.entityType = "lead";
      }
      await this.reloadTable();
    },

    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
  },

  async created() {
    this.isActive = true;
    await this.getModules();
  },
};
</script>

