<template>
  <b-overlay :show="isLoading" rounded="sm" :variant="skin">
    <b-card
      :style="{ borderRadius: '15px' }"
      :class="skin === 'dark' ? 'bg-dark' : ''"
    >
      <h3
        :class="skin === 'dark' ? 'text-w' : 'text-secondary'"
        style="font-weight: bold"
      >
        NCR PAYMENTS PER MONTH
      </h3>
      <e-charts
        ref="line"
        auto-resize
        autoresize
        :options="option"
        theme="theme-color"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import ECharts from "vue-echarts"
import VueApexCharts from "vue-apexcharts"
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index"

export default {
  components: {
    ECharts,
    VueApexCharts,
  },
  props: {
    year: [Number, String],
    typeClient: Number,
    clientOrLead: Number,
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        legend: {
          data: ["Pending Payment", "Complete Payment"],
          color: ["#0090e7", "#dc3545"],
          textStyle: {
            color: (params) => this.option.color[params.dataIndex],
          },
        },
        toolbox: {
          show: true,
          top: "center",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
            axisLabel: {
              color: "#525252",
              margin: 14,
              fontSize: 14,
              fontWeight: "500",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              margin: 10,
              fontSize: 14,
              fontWeight: "500",
              color: "#525252",
            },
            axisLine: { show: true },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#525252",
                type: "dashed",
                width: 1.3,
                interval: 10,
              },
            },
          },
        ],
        series: [
          {
            name: "Pending Payment",
            type: "bar",
            barGap: 0,
            color: "#0090e7",
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              rich: {
                name: {},
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "Complete Payment",
            type: "bar",
            color: "#dc3545",
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              rich: {
                name: {},
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      isLoading: false,
    }
  },
  methods: {
    async getChartNcrPayment() {
      try {
        this.isLoading = true
        const { data } = await SpecialistsDashboardService.getChartNcrPayment({
          date: this.year,
          client_type_id: this.typeClient,
          client_or_lead: this.clientOrLead,
        })
        this.option.xAxis[0].data = data.map((month) => month.months)
        this.option.series[0].data = data.map(
          (series) => series.pending_payment
        )
        this.option.series[1].data = data.map(
          (series) => series.complete_payment
        )
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.isLoading = false
      }
    },
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"]
    },
  },
  watch: {
    year() {
      this.getChartNcrPayment()
    },
    typeClient() {
      this.getChartNcrPayment()
    },
    clientOrLead() {
      this.getChartNcrPayment()
    },
  },
  async created() {
    await this.getChartNcrPayment()
  },
}
</script>

<style scoped>
.echarts {
  width: 100% !important;
}
.bg-dark {
  background: #17171a !important;
}
.text-w {
  color: #fff !important;
}
</style>
