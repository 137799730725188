var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xls","title":_vm.modalTitle,"title-tag":"h3","hide-footer":"","modal-class":"modal-primary","scrollable":""},on:{"hidden":_vm.closeModal},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-tabs',{attrs:{"pills":"","lazy":"","nav-class":"mt-2","active-nav-item-class":"bg-primary box-shadow-info"},on:{"input":_vm.handleTabChange}},[[_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":_vm.tabTitlePending},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"id":"ncr-requested","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.visibleFields,"show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[(data.item.status_account != null)?[(_vm.moduleId != 22)?_c('router-link',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"15px"},attrs:{"to":{
                    name: 'specialists-client-dashboard',
                    params: { idClient: ("" + (data.item.client_id)) },
                  },"target":"_blank"}},[_c('span',[_vm._v(" "+_vm._s(data.item.name)+" ")])]):_vm._e()]:[_c('span',{staticClass:"text-warning"},[_c('router-link',{staticClass:"text-warning",staticStyle:{"font-weight":"bolder"},attrs:{"target":"_blank","to":{
                      name: 'specialist-lead-show',
                      params: {
                        id: ("" + (data.item.lead_id)),
                      },
                    }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]],2),(data.item.status_account != null)?_c('status-account',{attrs:{"account":data.item,"text":true}}):_vm._e()]}},{key:"cell(account)",fn:function(data){return [(data.item.account)?_c('span',[_vm._v(_vm._s(data.item.account))]):_c('span',[_vm._v("-")])]}},{key:"cell(program)",fn:function(data){return [_c('div',{staticClass:"text-info text-center"},[_vm._v(" "+_vm._s(_vm.nameModule(data.item.program))+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}},{key:"cell(created_by)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.created_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.date))+" ")])]}}])})],1),(_vm.params.type != 2)?_c('b-tab',{attrs:{"title-link-class":[_vm.bgTabsNavs, 'sub-tab px-3'],"title":_vm.tabTitleComplete}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-table',{attrs:{"per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.tableProvider,"fields":_vm.visibleFields,"show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[(data.item.status_account != null)?[(_vm.moduleId != 22)?_c('router-link',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"15px"},attrs:{"to":{
                    name: 'specialists-client-dashboard',
                    params: { idClient: ("" + (data.item.client_id)) },
                  },"target":"_blank"}},[_c('span',[_vm._v(" "+_vm._s(data.item.name)+" ")])]):_vm._e()]:[_c('span',{staticClass:"text-warning"},[_c('router-link',{staticClass:"text-warning",staticStyle:{"font-weight":"bolder"},attrs:{"target":"_blank","to":{
                      name: _vm.leadDashboardRouteName,
                      params: {
                        id: ("" + (data.item.lead_id)),
                      },
                    }}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]],2),(data.item.status_account != null)?_c('status-account',{attrs:{"account":data.item,"text":true}}):_vm._e()]}},{key:"cell(account)",fn:function(data){return [(data.item.account)?_c('span',[_vm._v(_vm._s(data.item.account))]):_c('span',[_vm._v("-")])]}},{key:"cell(program)",fn:function(data){return [_c('div',{staticClass:"text-info text-center"},[_vm._v(" "+_vm._s(_vm.nameModule(data.item.program))+" ")])]}},{key:"cell(date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.value)))])]}},{key:"cell(created_by)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.created_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.date))+" ")])]}}],null,false,407690885)})],1):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }