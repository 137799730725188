<template>
  <b-row
    class="justify-content-md-around mb-2 p-0"
    align-v="stretch"
    style="gap: 10px"
  >
    <div
      v-for="(chartData, index) in chartDatas"
      :key="index"
      class="p-2 col br-15 col-12 col-md-12 col-lg-12 col-xl-11"
      :class="skinClass"
    >
      <b-overlay :show="isLoading" rounded="sm" :variant="skin">
        <div class="row justify-content-between align-items-center px-2">
          <h3 :class="textColorClass" class="font-weight-bolder">
            NCR {{ chartData.title }}
          </h3>
          <feather-icon
            class="cursor-pointer"
            icon="ListIcon"
            size="25"
            @click="showModal(chartData.status, chartData.type_ncr, 1)"
            v-b-tooltip.hover="'See more details'"
          />
        </div>
        <hr />
        <canvas :ref="chartData.ref"></canvas>
        <div class="chart-title row"></div>
      </b-overlay>
    </div>

    <custom-modal v-if="stateModal" :params="paramsModal" @close="hideModal" />
  </b-row>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import CustomModal from "@/views/specialists/views/dashboard_v2/components/modal/CustomModal.vue";
import Chart from "chart.js";
import "@/views/specialists/sub-modules/analyst-department/views/dashboard/utils/chartStyle.js";
import plugin from "@/views/specialists/sub-modules/analyst-department/views/dashboard/utils/doughnutEmptyState";

export default {
  components: {
    CustomModal,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
    typeClient: Number,
    clientOrLead: Number,
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
    skinClass() {
      return this.skin === "dark" ? "bg-dark" : "bg-light";
    },
    textColorClass() {
      return this.skin === "dark"
        ? "text-w text-uppercase"
        : "text-secondary text-uppercase";
    },
  },
  data() {
    return {
      chartDatas: [
        { ref: "chartAutomatic", title: "Automatic", status: 1, type_ncr: 1 },
        { ref: "chartManual", title: "Manual", status: 1, type_ncr: 2 },
      ],
      isLoading: false,
      stateModal: false,
      paramsModal: {
        status: null,
        year: null,
        month: null,
        type_ncr: null,
        type: null,
        type_client: null,
        client_or_lead: null,
      },
      chartInstance: null,
    };
  },
  created() {
    this.initCharts();
    this.getCardsData();
  },
  methods: {
    async getCardsData() {
      try {
        this.clearCharts();
        this.isLoading = true;
        const params = {
          year: this.year,
          month: this.month,
          client_type_id: this.typeClient,
          client_or_lead: this.clientOrLead,
        };
        const data = await SpecialistsDashboardService.getCardsData(params);
        this.chartDatas.forEach((chartData, index) => {
          this.updateChartData(chartData, data, index);
          this.renderChart(index);
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    initCharts() {
      this.chartDatas.forEach((chartData) => {
        chartData.datasets = [
          {
            data: [],
            backgroundColor: ["#dc3545", "#0090e7"],
            borderWidth: 0,
          },
        ];
        chartData.textCenter = [];
      });
    },
    renderChart(index) {
      try {
        const chartRef = this.chartDatas[index].ref;
        const chartData = this.chartDatas[index];
        this.chartInstance = new Chart(this.$refs[chartRef], {
          type: "RoundedDoughnut",
          data: {
            labels: this.labelChart(index),
            datasets: chartData.datasets,
          },
          options: {
            legend: {
              onClick: (e) => e.stopPropagation(),
              position: "right",
              labels: {
                usePointStyle: true,
                fontColor: "#928F8B",
                padding: 19,
              },
              align: "center",
            },
            cutoutPercentage: 75,
            animation: {
              animateScale: true,
              animateRotate: true,
            },
            plugins: {
              centerText: {
                lines: chartData.textCenter,
              },
              emptyDoughnut: {
                color: "#6c757d",
                width: 25,
                radiusDecrease: 12,
              },
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const value = dataset.data[tooltipItem.index];
                  return value;
                },
              },
            },
          },
          plugins: [plugin],
        });
        this[chartRef] = this.chartInstance;
      } catch (error) {}
    },
    labelChart(index) {
      const data = this.chartDatas[index].datasets[0].data;

      const formatValue = (value) => (value == null ? 0 : value);

      return [
        `${formatValue(data[0])} Complete`,
        `${formatValue(data[1])} Pending`,
      ];
    },
    clearCharts() {
      this.chartDatas.forEach((chartData) => {
        if (this[chartData.ref]) {
          this[chartData.ref].destroy();
        }
      });
    },
    updateChartData(chartData, data, index) {
      const totalKey = index === 1 ? "ncr_manual_total" : `ncr_automatic_total`;

      const paymentDataKey = index === 1 ? "ncr_manual" : `ncr_auto`;

      chartData.datasets[0].data =
        data[totalKey] != null && data[totalKey] !== "0"
          ? [
              data[paymentDataKey + "_completed"],
              data[paymentDataKey + "_pending"],
            ]
          : [];
      chartData.textCenter = [
        `${data[totalKey] == null ? 0 : data[totalKey]}`,
        "Total",
      ];
    },
    showModal(status, type_ncr, typeModal) {
      this.stateModal = true;
      this.paramsModal.status = status;
      this.paramsModal.year = this.year;
      this.paramsModal.month = this.month;
      this.paramsModal.type_ncr = type_ncr;
      this.paramsModal.type = typeModal;
      this.paramsModal.type_client = this.typeClient;
      this.paramsModal.client_or_lead = this.clientOrLead;
    },
    hideModal() {
      this.stateModal = false;
    },
  },
  watch: {
    year() {
      this.getCardsData();
      this.clearCharts();
    },
    month() {
      this.getCardsData();
      this.clearCharts();
    },
    typeClient() {
      this.getCardsData();
      this.clearCharts();
    },
    clientOrLead() {
      this.getCardsData();
      this.clearCharts();
    },
    skin() {
      this.clearCharts();
      this.getCardsData();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-dark {
  background: #17171a !important;
}
.bg-light {
  background: #fff !important;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.card-simple h3 {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.card-simple span {
  font-weight: bold;
  font-size: 25px;
  color: #fff;
}
.card-simple p {
  color: #fff;
  font-size: 13px;
}
.card-simple {
  padding: 0px;
}
.chart-title h1 {
  position: absolute;
  top: 25%;
  left: 80%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 150px;
}
.text-w {
  color: #fff;
}
.br-15 {
  border-radius: 15px;
}
</style>
