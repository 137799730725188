<template>
  <b-modal
    v-model="isActive"
    size="xls"
    :title="modalTitle"
    title-tag="h3"
    hide-footer
    modal-class="modal-primary"
    scrollable
    @hidden="closeModal"
  >
    <b-tabs
      pills
      lazy
      nav-class="mt-2"
      active-nav-item-class="bg-primary box-shadow-info"
      @input="handleTabChange"
    >
      <template>
        <b-tab
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          :title="tabTitlePending"
          v-model="tabIndex"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <b-table
            id="ncr-requested"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="visibleFields"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(name)="data">
              <span class="d-flex align-items-center" style="gap: 5px">
                <template v-if="data.item.status_account != null">
                  <router-link
                    class="d-flex align-items-center"
                    style="gap: 15px"
                    v-if="moduleId != 22"
                    :to="{
                      name: 'specialists-client-dashboard',
                      params: { idClient: `${data.item.client_id}` },
                    }"
                    target="_blank"
                  >
                    <span>
                      {{ data.item.name }}
                    </span>
                  </router-link>
                </template>
                <template v-else>
                  <span class="text-warning">
                    <router-link
                      class="text-warning"
                      style="font-weight: bolder"
                      target="_blank"
                      :to="{
                        name: 'specialist-lead-show',
                        params: {
                          id: `${data.item.lead_id}`,
                        },
                      }"
                    >
                      {{ data.item.name }}
                    </router-link>
                  </span>
                </template>
              </span>
              <status-account
                v-if="data.item.status_account != null"
                :account="data.item"
                :text="true"
              ></status-account>
            </template>
            <template #cell(account)="data">
              <span v-if="data.item.account">{{ data.item.account }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(program)="data">
              <div class="text-info text-center">
                {{ nameModule(data.item.program) }}
              </div>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
            <template #cell(created_by)="data">
              <div>
                {{ data.item.created_by }}
                <br />
                {{ data.item.date | myGlobalDay }}
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab
          v-if="params.type != 2"
          :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
          :title="tabTitleComplete"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <b-table
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableProvider"
            :fields="visibleFields"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(name)="data">
              <span class="d-flex align-items-center" style="gap: 5px">
                <template v-if="data.item.status_account != null">
                  <router-link
                    class="d-flex align-items-center"
                    style="gap: 15px"
                    v-if="moduleId != 22"
                    :to="{
                      name: 'specialists-client-dashboard',
                      params: { idClient: `${data.item.client_id}` },
                    }"
                    target="_blank"
                  >
                    <span>
                      {{ data.item.name }}
                    </span>
                  </router-link>
                </template>
                <template v-else>
                  <span class="text-warning">
                    <router-link
                      class="text-warning"
                      style="font-weight: bolder"
                      target="_blank"
                      :to="{
                        name: leadDashboardRouteName,
                        params: {
                          id: `${data.item.lead_id}`,
                        },
                      }"
                    >
                      {{ data.item.name }}
                    </router-link>
                  </span>
                </template>
              </span>
              <status-account
                v-if="data.item.status_account != null"
                :account="data.item"
                :text="true"
              ></status-account>
            </template>
            <template #cell(account)="data">
              <span v-if="data.item.account">{{ data.item.account }}</span>
              <span v-else>-</span>
            </template>
            <template #cell(program)="data">
              <div class="text-info text-center">
                {{ nameModule(data.item.program) }}
              </div>
            </template>
            <template #cell(date)="data">
              <span>{{ data.value | myGlobal }}</span>
            </template>
            <template #cell(created_by)="data">
              <div>
                {{ data.item.created_by }}
                <br />
                {{ data.item.date | myGlobalDay }}
              </div>
            </template>
          </b-table>
        </b-tab>
      </template>
    </b-tabs>
  </b-modal>
</template>

<script>
import SpecialistsDashboardService from "@/views/specialists/views/dashboard_v2/service/index";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    StatusAccount,
  },
  props: {
    params: Object,
  },
  data() {
    return {
      isActive: false,
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      activeTab: 1,
      tabIndex: 0,
      items: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: "Name",
          visible: true,
        },
        {
          key: "account",
          label: "Account",
          visible: true,
        },
        {
          key: "program",
          label: "module",
          visible: true,
          class: "text-center",
        },
        {
          key: "created_by",
          label: "requested by",
          visible: true,
        },
        {
          key: "specialist",
          label: "specialist",
          visible: true,
        },
        {
          key: "date",
          label: "Date",
          visible: true,
        },
      ];
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    modalTitle() {
      if (this.params.type_ncr == 1 && this.params.status == 1) {
        return "List of Pending Automatic NCR";
      } else if (this.params.type_ncr == 1 && this.params.status == 5) {
        return "List of Successful Automatic NCR";
      } else if (this.params.type_ncr == 2 && this.params.type == 1) {
        return "NCR Manual List";
      } else if (this.params.type == 2) {
        return "Work Plan List";
      } else if (this.params.type == 3) {
        return "Update Request List";
      }
    },
    tabTitlePending() {
      if (this.params.type_ncr == 1 && this.params.type == 1) {
        return "Pending";
      } else if (this.params.type_ncr == 2 && this.params.type == 1) {
        return "Pending";
      } else if (this.params.type == 2) {
        return "Workplan";
      } else if (this.params.type == 3) {
        return "Dispute Requested";
      }
    },
    tabTitleComplete() {
      if (this.params.type_ncr == 1 && this.params.type == 1) {
        return "Completed";
      } else if (this.params.type_ncr == 2 && this.params.type == 1) {
        return "Completed";
      } else if (this.params.type == 3) {
        return "Dispute Processed";
      }
    },
    statusTypeNcr() {
      if (this.params.type == 1) {
        return this.tabIndex == 0 ? 1 : 3;
      } else if (this.params.type == 2) {
        return 1;
      }
    },
    statusPayment() {
      return null;
    },
    typeModal() {
      if (this.params.type == 3) {
        return this.tabIndex == 0 ? 4 : 3;
      } else {
        return this.params.type;
      }
    },
    validateColumnSpecialist() {
      if (this.params.client_or_lead == 1 && this.params.type != 1) {
        return true;
      }
    },
    validateColumnCreatedBy() {
      if (this.params.client_or_lead == 1 && this.params.type != 1) {
        return false;
      }
    },
  },
  created() {
    this.fields.find((field) => field.key == "specialist").visible =
      this.validateColumnSpecialist;
    this.fields.find((field) => field.key == "date").visible =
      this.validateColumnCreatedBy;
    this.fields.find((field) => field.key == "account").visible =
      this.params.client_or_lead == 1 ? false : true;
    this.isActive = true;
  },
  methods: {
    closeModal() {
      this.totalRows = 0;
      this.perPage = 10;
      this.currentPage = 1;
      this.isActive = false;
      this.$emit("close");
    },
    async tableProvider() {
      const params = {
        year: this.params.year,
        month: this.params.month,
        status: this.statusTypeNcr,
        status_payment: this.statusPayment,
        type_ncr: this.params.type_ncr,
        type: this.typeModal,
        perPage: this.perPage,
        nPage: this.currentPage,
        client_type_id: this.params.type_client,
        client_or_lead: this.params.client_or_lead,
      };
      const data = await SpecialistsDashboardService.getModalData(params);
      if (data.data.length != 0) {
        this.totalRows = data.data[0].total;
      }
      this.items = data.data;
      return data.data;
    },
    handleTabChange(newTabIndex) {
      this.tabIndex = newTabIndex;
    },
    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
  },
};
</script>
